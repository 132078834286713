export const Messages = Object.freeze({
  errors: {
    general:
      'Se ha producido un error inesperado. Por favor, póngase en contacto con el soporte técnico o inténtelo nuevamente más tarde. Disculpe las molestias.',
    created: 'El registro no fue creado. Por favor, corrija los datos e inténtelo nuevamente.',
    extracted:
      'Se ha producido un error inesperado al intentar extraer los datos. Por favor, verifique el formato del archivo e inténtelo nuevamente.',
    updated: 'El registro no fue actualizado. Por favor, corrija los datos e inténtelo nuevamente.',
    deleted: 'El registro no fue eliminado. Inténtelo más tarde.',
    invalid: 'Corrija los datos e inténtelo nuevamente',
  },
  confirm: '¿Estás seguro de eliminar este registro?',
  created: 'El registro fue creado satisfactoriamente.',
  updated: 'El registro fue actualizado satisfactoriamente.',
  deleted: 'El registro fue eliminado satisfactoriamente.',
  uploaded: 'El recuros fue cargado correctamente',
  empty: 'No se han encontrado resultados.',
});
