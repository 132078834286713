export const BASE_CONF = {
  BASE_ACTIVE_INACTIVE_SELECTOR: [
    {
      id: 1,
      label: 'Habilitado',
    },
    {
      id: 2,
      label: 'Deshabilitado',
    },
  ],
  ENABLE_TYPES_PROPERTIES: [
    {
      id: 1,
      label: 'Texto',
    },
    {
      id: 2,
      label: 'Decimal',
    },
    {
      id: 3,
      label: 'Numero Entero',
    },
    {
      id: 4,
      label: 'Fecha',
    },
  ],
  STATUS_PROCESS: {
    1: 'Revision',
    2: 'Aprobado',
    3: 'Rechazado',
  },
  LANGUAGES: [
    {
      id: 'es',
      label: 'Español',
    },
    {
      id: 'en',
      label: 'Ingles',
    },
  ],
  FORMAT_FILES: [
    {
      format: '.pdf',
      icon: 'pdf-icon.png',
    },
    {
      format: '.docx',
      icon: 'docx-icon.png',
    },
    {
      format: '.doc',
      icon: 'docx-icon.png',
    },
    {
      format: '.ppt',
      icon: 'pptx-icon.png',
    },
    {
      format: '.pptx',
      icon: 'pptx-icon.png',
    },
    {
      format: '.xlsx',
      icon: 'xlsx-icon.png',
    },
    {
      format: '.xls',
      icon: 'xlsx-icon.png',
    },
    {
      format: '.csv',
      icon: 'csv-icon.png',
    },
    {
      format: '.png',
      icon: 'png-icon.png',
    },
    {
      format: '.jpg',
      icon: 'jpg-icon.png',
    },
    {
      format: '.jpeg',
      icon: 'jpeg-icon.png',
    },
    {
      format: 'OTHER',
      icon: 'other-icon.png',
    },
  ],
};
