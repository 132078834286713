import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { find, get } from 'lodash';
import { CredentialsService } from '../services';
import { PermissionService } from '../services/permission.service';
export type permissionTypes = 'canCreate' | 'canView' | 'canUpdate' | 'canDelete' | 'isAdmin' | 'isSuperAdmin';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective implements OnInit {
  @Input() public resource!: string;
  @Input() public enableActions!: permissionTypes;
  @Input() public isPublicView: boolean = false;

  public userData: any = this.credentialsService.getLoggedUser();
  public rights: any = this.credentialsService.getCreds();
  public removeElement: boolean = true;
  constructor(
    private el: ElementRef,
    private readonly credentialsService: CredentialsService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    if (!this.isPublicView) {
      const permission = this.permissionService.handleAccess(this.resource, this.enableActions, this.isPublicView);
      if (!permission) {
        this.removeElementFromDom();
      }
    }
  }

  public removeElementFromDom(): void {
    if (this.removeElement) {
      this.el.nativeElement.remove();
    }
  }
}
