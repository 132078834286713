import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/shared/services/app.layout.service';

@Component({
  selector: 'app-side-bar-template',
  templateUrl: './side-bar-template.component.html',
  styleUrls: ['./side-bar-template.component.scss'],
})
export class SideBarTemplateComponent {
  constructor(public layoutService: LayoutService, public el: ElementRef) {}
}
