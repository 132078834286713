<ul class="layout-menu">
  <ng-container *ngFor="let item of model; let i = index">
    <li
      app-menu-item
      *ngIf="!item.separator"
      [item]="item"
      [index]="i"
      [root]="true"
      appPermission
      [resource]="item?.resourceName"
      [enableActions]="item?.right"
      [isPublicView]="item?.isPublic"
    ></li>
    <li *ngIf="item.separator" class="menu-separator"></li>
  </ng-container>
  <li>
    <!-- SOME IMAGE HERE -->
    <!-- <a href="https://www.primefaces.org/primeblocks-ng/#/">
          <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'banner-primeblocks' : 'banner-primeblocks-dark'}}.png" alt="Prime Blocks" class="w-full mt-3"/>
      </a> -->
  </li>
</ul>
