import { BASE_CONF } from './base';

export const environment = {
    ...BASE_CONF,
    production: true,
    host: 'https://smtcc.bits.bo/api',
    captchaKey: '6LeKC90mAAAAAM0I9k8GzUGBXWojbPR59smATKwO',
    captchaEnabled: false,
    //captchaKey: "6Ldqhz0aAAAAAPln22bmygUUqHKcE_SS8AI_Iofy",
    handsontableKey: 'non-commercial-and-evaluation',
    // urlGeoserver: 'http://localhost:8080/geoserver',
    urlGeoserver: 'https://geoserver.bits.bo/geoserver',
    workspaceGeoserver: 'smtcc'
  };
