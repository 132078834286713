import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Service to Emit data between components to share data, objects, etc.
 * The components that will be use this service don't need to be connected between them directly if is necessary.
 *
 * @export
 * @class EmitFunctionsService
 */
@Injectable({
  providedIn: 'root',
})
export class EmitFunctionsService {
  public subject = new Subject<any>();

  /**
   * Creates an instance of EmitFunctionsService.
   *
   * @param {Store<any>} _store
   * @memberof EmitFunctionsService
   */
  constructor() {}

  /**
   * Emit the data param.
   *
   * @param {*} data
   * @memberof EmitFunctionsService
   */
  public sendData(data: any): void {
    this.subject.next(data);
  }

  /**
   * Clears the cache data.
   *
   * @memberof EmitFunctionsService
   */
  public clearData(): void {
    this.subject.next(undefined);
  }

  /**
   * Returns the data as an "Observable" object type.
   *
   * @returns {Observable<any>}
   * @memberof EmitFunctionsService
   */
  public getData(): Observable<any> {
    return this.subject.asObservable();
  }
}
