import { Injectable } from '@angular/core';
// import * as dayjs from 'dayjs';
import { Translation } from 'primeng/api';
import { User } from '../../modules/user/interfaces';
import { environment } from '../../../environments/environment';
const dayjs = require('dayjs');

@Injectable({
  providedIn: 'root',
})
export class GenericFunctions {
  constructor() {}
  /**
   * Para que los calendarios primeNG se muestren en español
   */
  public dateEsConfig(): Translation {
    return {
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
      today: 'Hoy',
      clear: 'Borrar',
    };
  }

  static truncateText(value: string, maxLength = 20) {
    if (value.length <= maxLength) return value;

    return value.slice(0, maxLength) + '...';
  }

  public parseData(data: Array<any>, keys: Array<any>, f: Array<any>): Array<any> {
    data.forEach((element) => {
      // tslint:disable-next-line: forin
      for (const key in element) {
        keys.forEach((k, index) => {
          if (key === k) {
            if (typeof f[index] === 'string') {
              element[key] = dayjs(element[key]).format('DD/MM/YY');
            } else {
              const temp = f[index].find((e: any) => e.value === element[key]);
              element[key] = temp.label;
            }
          }
        });
      }
    });
    return data;
  }

  public static getUserFullName(user: User, abbr = true) {
    const { primerNombre, segundoNombre, apellidoMaterno, apellidoPaterno } = user || {};

    if (!primerNombre || !apellidoPaterno) return '';
    if (abbr)
      return `${primerNombre} ${this.abbrName(segundoNombre)} ${apellidoPaterno} ${this.abbrName(apellidoMaterno)}`;

    return `${primerNombre} ${segundoNombre || ''} ${apellidoPaterno} ${apellidoMaterno}`;
  }

  public static abbrName(name: string = '') {
    if (!name) return '';

    return `${name.charAt(0).toUpperCase()}.`;
  }

  public getSwalDefaults(): any {
    return {
      text: 'Está seguro que desea guardar',
      type: 'question',
      position: 'center',
      showCancelButton: true,
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      heightAuto: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      cancelButtonClass: 'btn btn-lg btn-default',
      confirmButtonClass: 'btn btn-lg btn-primary ml-2',
      icon: 'question',
    };
  }

  static SortObjectArray(list: any, field: string): any {
    try {
      return list.sort((a: any, b: any) => {
        const tituloA = a[field]
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const tituloB = b[field]
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        return tituloA.localeCompare(tituloB);
      });
    } catch (err) {
      return list;
    }
  }
  static CartographicPaths(): { urlImg: string; urlWms: string; urlWmsDownload: string } {
    return {
      urlImg: environment.host + '/upload/layers/download/',
      urlWms: `${environment.urlGeoserver}/${environment.workspaceGeoserver}/wms`,
      urlWmsDownload: `${environment.urlGeoserver}/${environment.workspaceGeoserver}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${environment.workspaceGeoserver}`,
    };
  }
  static getRandomColor(opacity = 1): string {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    return `rgba(${r},${g},${b}, ${opacity})`;
  }

  static getSimilarColor(hexColor: string, factor = Math.random()) {
    // Convert the hex color to RGB
    const rgb: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    const r = parseInt(rgb[1], 16);
    const g = parseInt(rgb[2], 16);
    const b = parseInt(rgb[3], 16);

    // Adjust the RGB values to create a similar color
    const similarityFactor = factor; // Adjust this value for more or less similarity
    const newR = Math.floor(r * similarityFactor);
    const newG = Math.floor(g * similarityFactor);
    const newB = Math.floor(b * similarityFactor);

    // Convert the adjusted RGB values back to hex
    const newHexColor = `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB
      .toString(16)
      .padStart(2, '0')}`;

    return newHexColor;
  }

  static getRGBAColor(hexColor: string, alpha = 0.5): string {
    const rgb: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    const r = parseInt(rgb[1], 16);
    const g = parseInt(rgb[2], 16);
    const b = parseInt(rgb[3], 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  static generateColorPalette(baseColor: string) {
    let r = parseInt(baseColor.substring(1, 3), 16);
    let g = parseInt(baseColor.substring(3, 5), 16);
    let b = parseInt(baseColor.substring(5, 7), 16);

    const darkFactor1 = 0.8;
    const darkFactor2 = 0.9;
    const lightFactor1 = 1.1;
    const lightFactor2 = 1.2;

    const adjustColor = (color: number, factor: number) => Math.round(Math.min(Math.max(0, color * factor), 255));

    let darkColor1 = `#${adjustColor(r, darkFactor1).toString(16).padStart(2, '0')}${adjustColor(g, darkFactor1)
      .toString(16)
      .padStart(2, '0')}${adjustColor(b, darkFactor1).toString(16).padStart(2, '0')}`;
    let darkColor2 = `#${adjustColor(r, darkFactor2).toString(16).padStart(2, '0')}${adjustColor(g, darkFactor2)
      .toString(16)
      .padStart(2, '0')}${adjustColor(b, darkFactor2).toString(16).padStart(2, '0')}`;
    let lightColor1 = `#${adjustColor(r, lightFactor1).toString(16).padStart(2, '0')}${adjustColor(g, lightFactor1)
      .toString(16)
      .padStart(2, '0')}${adjustColor(b, lightFactor1).toString(16).padStart(2, '0')}`;
    let lightColor2 = `#${adjustColor(r, lightFactor2).toString(16).padStart(2, '0')}${adjustColor(g, lightFactor2)
      .toString(16)
      .padStart(2, '0')}${adjustColor(b, lightFactor2).toString(16).padStart(2, '0')}`;

    return [darkColor1, darkColor2, baseColor, lightColor1, lightColor2];
  }

  static getTextColorForBackground(backgroundColor: string) {
    const hex = backgroundColor.replace('#', '');

    // Convertir el color hexadecimal a decimal
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calcular la luminancia relativa del color
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Devolver el color de texto apropiado según el umbral de luminancia
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }
}
