import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

import size from 'lodash-es/size';

type PasswordStrengthValidation = null | {
  pswLength?: boolean;
  pswLowercase?: boolean;
  pswUppercase?: boolean;
  pswNumber?: boolean;
  pswSymbol?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class PasswordValidator {
  static passwordValidator(control: FormControl): PasswordStrengthValidation {
    const minLength: number = 8;
    const mustLowerCase: boolean = true;
    const mustUpperCase: boolean = true;
    const mustNumber: boolean = true;
    const mustSymbol: boolean = true;
    const out: PasswordStrengthValidation = {};
    const val = control.value;

    if (val) {
      if (val.length < minLength) {
        out.pswLength = true;
      }

      if (mustLowerCase && !/[a-z]/.test(val)) {
        out.pswLowercase = true;
      }

      if (mustUpperCase && !/[A-Z]/.test(val)) {
        out.pswUppercase = true;
      }

      if (mustNumber && !/\d/.test(val)) {
        out.pswNumber = true;
      }

      if (mustSymbol && !/[\W_]/.test(val)) {
        out.pswSymbol = true;
      }
    }

    return size(out) ? out : null;
  }
}
