import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CredentialsService } from 'src/app/shared/services';
import { coreFunctions } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private credentialsService: CredentialsService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // START BASIC TOKEN VALIDATION
    const tokenData: any = this.credentialsService.getToken();
    coreFunctions.main();
    if (tokenData) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
    // END BASIC TOKEN VALIDATION
  }
}
