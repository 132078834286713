export const Resource = Object.freeze({
  USUARIOS: 'USUARIOS',
  ROLES: 'ROLES',
  PERMISOS_SISTEMA: 'PERMISOS_SISTEMA',
  USUARIOS_ADMIN_MENU: 'USUARIOS_ADMIN_MENU',
  LOG_ACTIVIDADES: 'LOG_ACTIVIDADES',
  ADMINISTRACION_PLANES_MENU: 'ADMINISTRACION_PLANES_MENU',
  TIPO_PLANES: 'TIPO_PLANES',
  PLANES: 'PLANES',
  AMBITOS: 'AMBITOS',
  SECTORES: 'SECTORES',
  CATEGORIAS: 'CATEGORIAS',
  ESTANDARES: 'ESTANDARES',
  ADMINISTRACION_ESTANDARES_MENU: 'ADMINISTRACION_ESTANDARES_MENU',
  ADMINISTRACION_DOCUMENTOS_MENU: 'ADMINISTRACION_DOCUMENTOS_MENU',
  TIPO_DOCUMENTOS: 'TIPO_DOCUMENTOS',
  GESTION_DOCUMENTOS: 'GESTION_DOCUMENTOS',
  DOCUMENTOS_ESTANDAR: 'DOCUMENTOS_ESTANDAR',
  ADMINISTRACION_RECURSOS_INFORMACION: 'ADMINISTRACION_RECURSOS_INFORMACION',
  ADMINISTRACION_RECURSOS_CARTOGRAFICOS: 'ADMINISTRACION_RECURSOS_CARTOGRAFICOS',
  SERVICIOS_MAPAS_WEB: 'SERVICIOS_MAPAS_WEB',
  MENU_GEOVISOR: 'MENU_GEOVISOR',
  GEOVISOR: 'GEOVISOR', //external
  INTERNAL_GEOVISOR: 'INTERNAL_GEOVISOR',
  RECURSOS_INFORMACION: 'RECURSOS_INFORMACION',
  RECURSOS_CARTOGRAFICOS: 'RECURSOS_CARTOGRAFICOS',
  MENU_DASHBOARDS: 'MENU_DASHBOARDS',
  GADGETS: 'GADGETS',
  DASHBOARDS: 'DASHBOARDS',
  MENU_BIG_DATA: 'MENU_BIG_DATA',
  BIG_DATA_SECTORS_NDC: 'BIG_DATA_SECTORS_NDC',
  BIG_DATA_GOALS_NDC: 'BIG_DATA_GOALS_NDC',
  BIG_DATA_INDICATORS_NDC: 'BIG_DATA_INDICATORS_NDC',
  BIG_DATA_UOM_NDC: 'BIG_DATA_UOM_NDC',
  MENU_BIG_DATA_TRACKING: 'MENU_BIG_DATA_TRACKING',
  BIG_DATA_ASSIGNMENTS: 'BIG_DATA_ASSIGNMENTS',
  BIG_DATA_TRACKING_CARDS: 'BIG_DATA_TRACKING_CARDS',
  BIG_DATA_SUBMIT_REPORT: 'BIG_DATA_SUBMIT_REPORT',
  MENU_BIG_DATA_DASHBOARDS: 'MENU_BIG_DATA_DASHBOARDS',
  BIG_DATA_DASHBOARDS: 'BIG_DATA_DASHBOARDS',
  BIG_DATA_REPORTS: 'BIG_DATA_REPORTS',
  SDV_DASHBOARDS: 'SDV_DASHBOARDS',
  SDV_CATEGORIES: 'SDV_CATEGORIES',
  SDV_INDICATOR_TYPES: 'SDV_CATEGORIES',
  SDV_DATA_REPO: 'SDV_DATA_REPO',
  ENV_COMPONENTS: 'ENV_COMPONENTS',
  INDICATOR_ENV_COMPONENTS: 'INDICATOR_ENV_COMPONENTS',
});
