import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TemplatesModule } from './templates/templates.module';
import { httpInterceptorProviders } from './shared/interceptors/http-request-interceptor.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ToasterService } from './shared/services/toaster.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { NotfoundComponent } from './shared/pages/notfound/notfound.component';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es-BO');

@NgModule({
  declarations: [AppComponent, NotfoundComponent],
  imports: [AppRoutingModule, TemplatesModule, ToastModule, ConfirmDialogModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-BO' },
    httpInterceptorProviders,
    MessageService,
    ToasterService,
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
