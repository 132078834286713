<button class="layout-config-button p-link" type="button" (click)="onConfigButtonClick()">
  <i class="pi pi-cog"></i>
</button>

<p-sidebar
  [(visible)]="visible"
  position="right"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="layout-config-sidebar w-20rem"
>
  <ng-container *ngIf="!minimal">
    <h5>Tipo de Menu</h5>
    <div class="field-radiobutton">
      <p-radioButton name="menuMode" value="static" [(ngModel)]="menuMode" inputId="mode1"></p-radioButton>
      <label for="mode1">Estatico</label>
    </div>
    <div class="field-radiobutton">
      <p-radioButton name="menuMode" value="overlay" [(ngModel)]="menuMode" inputId="mode2"></p-radioButton>
      <label for="mode2">Flotante</label>
    </div>
  </ng-container>

  <h5>Temas</h5>
  <div class="grid">
    <div class="col-3">
      <button class="p-link w-2rem h-2rem" (click)="changeTheme('bootstrap4-light-purple', 'light')" title="Claro">
        <img
          src="assets/layout/images/themes/bootstrap4-light-purple.svg"
          class="w-2rem h-2rem"
          alt="Bootstrap Light Purple"
        />
      </button>
    </div>
    <div class="col-3">
      <button class="p-link w-2rem h-2rem" (click)="changeTheme('bootstrap4-dark-purple', 'dark')" title="Oscuro">
        <img
          src="assets/layout/images/themes/bootstrap4-dark-purple.svg"
          class="w-2rem h-2rem"
          alt="Bootstrap Dark Purple"
        />
      </button>
    </div>
  </div>
</p-sidebar>
