<div class="layout-topbar pl-0">
  <a class="layout-topbar-logo block pl-10 bg-white" routerLink="">
    <div class="w-full text-white flex justify-content-center align-items-center bg-white">
      <img src="assets/img/LOGO_3.png" alt="logo" />
    </div>
  </a>

  <div class="flex justify-content-between flex-grow-1 align-items-center">
    <div>
      <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
      </button>

      <button
        #topbarmenubutton
        class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()"
      >
        <i class="pi pi-ellipsis-v"></i>
      </button>
    </div>
    <div>
      <span class="text-3xl">SMTCC</span>
    </div>

    <div
      #topbarmenu
      class="rounded layout-topbar-menu hover:bg-black-alpha-40 hover:text-white p-1"
      [ngClass]="{ 'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible }"
    >
      <button class="p-link p-1" (click)="menu.toggle($event)" style="min-width: 150px">
        <div class="m-0 w-full flex gap-2 justify-content-end text-black align-items-center layout-topbar-button">
          <div class="layout-topbar-button m-0 text-black hover:text-white">
            <i class="pi pi-user"></i>
            <span> Perfil</span>
          </div>
          <div>
            <label>{{ getCurrentUserName() }}</label>
            <p class="text-sm">{{ getCurrentRole }}</p>
          </div>
        </div>
      </button>

      <p-menu #menu [appendTo]="'body'" [popup]="true" [model]="items"></p-menu>
    </div>
  </div>
</div>
