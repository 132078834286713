import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/services';
import { lastValueFrom } from 'rxjs';
import { IAttachment } from '../interfaces';
import compact from 'lodash-es/compact';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  constructor(private readonly _baseHttpService: BaseHttpService, private httpClient: HttpClient) {}

  async upload(data: FormData): Promise<IAttachment[]> {
    return lastValueFrom(this._baseHttpService.post('attachments/upload', data));
  }

  getFileUrl(key?: string, download?: boolean) {
    if (!key) return '#';
    const apiUrl = environment.host;
    const url = encodeURI(compact([apiUrl, 'attachments', key]).join('/'));

    if (download) return `${url}?download=${download}`;

    return url;
  }

  downloadFile(key?: string) {
    const url = `${this.getFileUrl(key)}?download=true`;

    return this.httpClient.get(url, { responseType: 'blob' });
  }
}
